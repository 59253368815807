@import "../../Assets/sass/variable-colors";
.toolbar {
    height: 75px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $contentBg;
    display: flex;
    box-shadow:  0 2px 4px 0 #e5ebef;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    nav {
        height: 100%;
        float: right;
        position: relative;
        margin-top: 0;
    }
    
    .Logo {
        height: 100%;
        float: left;
        margin: 0 0;
        width: 62px;
        position: relative;
        overflow: hidden;
    }
}