$white: #fff;
$black: #000000;
$font-black: #333333;
$darkGrey: #4d4d4d;
$whiteGrey: #f7f7f7;
$darksilverGrey: #7d7d7d;
$darkGreen: #11003f;
$lightGrey: #cccccc;
$lightbgGrey: #f2f2f2;
$lightWhiteGray: #e6e6e6;
$colorGray: #808080;
$redGray: #999999;
$blue: #3566e6;
$lightWhite: #fafafa;
$drakWhiteGray: #d6d6d6;
$drakGrayRed: #9a9a9a;
$primaryColor: #0077ca; // Primary color
$secondaryColor: #ffffff; // Secondary color
$primaryHoverColor:#0260a2; // Hover color for primary button 
$secondaryButtonColor: #ffffff;
$contentBg: #f4fafd; // Background color
$thBg: #fff0c1; // Table Heading background color
$darkRed: #d0021b; // Error messages 
$darkGrey: #252424;
$lightGrey: #777777;