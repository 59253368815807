/* main/reset/common css */
/* @import "../sass/_mixins";*/
@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
body {
  font-family: "Rubik", sans-serif;
  font-size: 0.875rem;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4fafd; }

/* common utility classes */
.clear {
  clear: both; }

.border {
  border: solid 1px #dadada; }

.opacity-7 {
  opacity: 0.7; }

.opacity-7 ~ .status-dd .dropdown-toggle.btn-icon {
  padding-left: 0;
  opacity: 0.7; }

.cursor-pointer {
  cursor: pointer; }

.border-none {
  border: none; }

input::-ms-reveal,
input::-ms-clear {
  display: none; }

/* font colors */
.color-gray {
  color: #757575; }

/* font weight */
.fw-600 {
  font-weight: 600; }

.capitalized {
  text-transform: capitalize; }

.padding-0 {
  padding: 0; }

.border-top-0 td,
.border-top-0 th,
.border-top-0 {
  border-top: 0; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mlr-0 {
  margin-left: 0;
  margin-right: 0; }

.mt-0 {
  margin-top: 0; }

.mt-05 {
  margin-top: 5px; }

.mt-7 {
  margin-top: 7px; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px; }

.mt-100 {
  margin-top: 100px; }

.mb-0 {
  margin-bottom: 0; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.ml-05 {
  margin-left: 5px; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-10 {
  margin-left: 10px; }

.mr-05 {
  margin-right: 5px; }

.mr-15 {
  margin-right: 15px; }

.mr-30 {
  margin-right: 30px; }

.ml-30 {
  margin-left: 30px; }

.ml-35 {
  margin-left: 35px; }

.ml-37 {
  margin-left: 37px; }

.ml-60 {
  margin-left: 60px; }

.invisible {
  visibility: hidden; }

.line-height-1-93 {
  line-height: 1.93; }

.title-bg th,
.vertical-middle {
  vertical-align: middle !important; }

.vertical-top {
  vertical-align: top !important; }

.vertical-bottom {
  vertical-align: bottom !important; }

.width-70 {
  width: 70px !important; }

.inline-block .width-60.form-control,
.width-60 {
  width: 60px; }

.width-84 {
  width: 84px; }

.width-100 {
  width: 100px; }

.width-110 {
  width: 110px; }

.width-250 {
  width: 250px; }

.min-width-84 {
  min-width: 84px !important; }

.min-height-block {
  min-height: 440px; }

.min-height-260 {
  min-height: 245px;
  padding-bottom: 15px; }

.text-center th {
  text-align: center; }

/* login css */
.login-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url(../../Assets/Images/login-bg.jpg);
  background-color: #3680d7;
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: cover; }

.logo-holder img {
  width: 74px;
  height: auto;
  margin: 35px 0 60px; }

.logo-holder .text-md {
  color: #fff;
  margin-top: 80px; }

.login-title {
  color: #fff;
  font-size: 40px;
  letter-spacing: 0.24px;
  line-height: 1.38;
  font-weight: 600; }

.download-button {
  background-color: #0077ca;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  border: 0;
  margin: 10px 0 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  padding: 8px 16px; }
  .download-button .download-text {
    display: inline-block;
    padding-left: 10px;
    text-align: left;
    vertical-align: top; }
  .download-button i {
    font-size: 30px;
    display: inline-block;
    margin-top: 5px;
    font-size: 32px; }
  .download-button:hover, .download-button:focus {
    background-color: #0260a2;
    color: white;
    border: 0;
    outline: none; }

.thank-you-msg {
  padding: 1rem 25px 30px;
  font-size: 0.875rem;
  text-align: center; }
  .thank-you-msg .modal-header {
    padding: 0; }
  .thank-you-msg img {
    vertical-align: top;
    margin-top: 1px;
    margin-right: 3px; }
  .thank-you-msg .modal-title {
    color: #000;
    margin-bottom: 5px; }
  .thank-you-msg .message {
    padding: 0 0 30px;
    margin-top: -10px; }
    .thank-you-msg .message:after {
      content: "";
      position: absolute;
      border-bottom: 1px solid #cccccc;
      width: calc(100% - 50px);
      margin: 0 auto;
      left: 0;
      right: 0;
      margin-top: 15px; }
  .thank-you-msg .product-link {
    cursor: pointer;
    background-color: transparent;
    color: #0077ca;
    box-shadow: none;
    line-height: 30px;
    display: inline-block;
    margin: 0 10px 5px 10px;
    border: 1px solid #0077ca;
    padding: 4px 30px 0;
    border-radius: 20px; }
    .thank-you-msg .product-link i {
      font-size: 1rem;
      margin-right: 6px; }
    .thank-you-msg .product-link:hover, .thank-you-msg .product-link:focus, .thank-you-msg .product-link:active {
      background-color: #0077ca;
      text-decoration: none;
      color: #ffffff;
      outline: none; }

.login-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 38px;
  font-size: 12px;
  padding-top: 10px;
  color: #e8e7e7;
  background-color: rgba(0, 0, 0, 0.65); }
  .login-footer .container {
    text-align: center; }

@media (max-width: 768px) {
  .login-footer {
    text-align: center; }
    .login-footer .copyright-text {
      width: 100%; } }

.login-form {
  position: relative; }
  .login-form .form-fields {
    min-height: 85px;
    position: relative; }
  .login-form .show-password {
    position: absolute;
    bottom: 29px;
    right: 0;
    height: 29px;
    width: 28px;
    cursor: pointer;
    min-height: auto;
    padding: 4px;
    text-align: center;
    color: #777777;
    min-height: 29px !important; }
    .login-form .show-password:focus {
      outline: 0 !important; }
  .login-form ::-webkit-input-placeholder {
    color: #adadad; }
  .login-form ::-moz-placeholder {
    color: #adadad; }

@media (min-width: 768px) {
  .logo-holder {
    min-height: 210px; }
    .logo-holder .text-md {
      margin-right: 5px; } }

@media (max-width: 767px) {
  .login-title {
    font-size: 18px; }
  .logo-holder img {
    margin: 15px 0 20px;
    width: 54px; }
  .logo-holder .text-md {
    margin-top: 40px; }
  .login-footer {
    padding-top: 10px; } }

.show-password:hover {
  color: #0077ca; }

.no-admin-data {
  font-size: 18px;
  color: #999999;
  text-align: center;
  margin-top: 5%; }

.no-data {
  color: #000000;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px; }

.update-pw-form {
  position: relative; }

.update-pw-form > div {
  min-height: 50px; }

.info {
  color: #777777;
  margin: 15px 0 25px;
  text-align: center;
  line-height: 20px; }

.info .user-email {
  color: #000000;
  display: block; }

.info .check-icon {
  color: #0077ca;
  display: block;
  font-size: 28px;
  margin-bottom: 20px; }

.inline-block .form-control {
  display: inline-block;
  width: auto; }

form .col-md-2 .form-control.width-60 {
  display: inline-block;
  width: 68px;
  overflow: hidden;
  margin-left: 10px; }

/* Heading style */
.container > h3:first-child,
.container > .nav-trail h3:first-child {
  font-size: 18px;
  color: #000000;
  margin: 25px 0 15px; }

/* Tabs reset */
.tab-content {
  min-height: 250px; }

.nav-tabs {
  position: relative;
  border-bottom: 0; }

.nav-tabs:after {
  border-bottom: 1px solid #dee2e6;
  width: calc(100% + 20px);
  left: -10px;
  position: absolute;
  content: "";
  bottom: -2px; }

.nav-tabs .nav-link {
  color: #777777;
  padding: 1rem;
  text-align: center;
  border: 0 solid transparent;
  cursor: pointer; }

.nav-tabs .nav-link:focus,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 4px solid #0077ca;
  color: #0077ca;
  outline: none; }

.nav-tabs .nav-link.active:hover {
  border-bottom: 4px solid #0077ca; }

.nav-tabs .nav-link:hover {
  border: 0;
  border-bottom: 4px solid transparent;
  color: #0077ca; }

.nav-trail h3 {
  display: inline-block; }

.nav-trail .fa-home {
  font-size: 12px;
  color: #888888; }

.nav-trail span {
  font-size: 11px;
  color: #777777; }
  .nav-trail span:last-child {
    color: #0077ca; }

.nav-trail .nav-trail-label span {
  position: relative;
  padding-left: 16px; }
  .nav-trail .nav-trail-label span:after {
    content: "-";
    color: #777777;
    position: absolute;
    left: 5px;
    top: -1px;
    font-weight: 700; }

/* common font size */
.text-xxsm {
  font-size: 10px !important; }

.text-xsm {
  font-size: 12px !important; }

.text-xsm-13 {
  font-size: 13px !important; }

.text-sm {
  font-size: 14px !important; }

.text-md {
  font-size: 16px; }

.text-lg {
  font-size: 18px; }

.text-xl {
  font-size: 20px; }

.text-xxl {
  font-size: 22px; }

.text-xxxxl {
  font-size: 26px; }

.d-none {
  display: none; }

.dark-grey {
  color: #252424; }

.light-grey {
  color: #777777; }

.error-message {
  color: #d0021b;
  font-size: 13px;
  margin: 3px 0 10px 1px;
  text-align: left; }
  .error-message .fa-exclamation-triangle {
    font-size: 12px;
    margin-right: 2px; }

.text-danger {
  color: #d0021b; }

.login-form .error-message {
  font-size: 12px; }

/* button loader */
.btn-primary.show-btn-loader:disabled,
.btn-primary.show-btn-loader:disabled:hover {
  opacity: 1; }

.show-btn-loader .btn-loader {
  vertical-align: bottom;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
  transition: all 0.7s ease; }
  .show-btn-loader .btn-loader .loader-icon {
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-top: 2px solid rgba(255, 255, 255, 0.95);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    display: inline-block;
    animation: spin 0.75s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

/* common table style */
.edit-subscription-table {
  margin: 0 15px 0; }

.active-status {
  color: #417505; }

.inactive-status {
  color: #d0021b; }

div > .table {
  margin-bottom: 0; }

.table {
  color: #000000; }

.table .pb-01 th {
  padding-bottom: 0; }

.th-row-subheading th {
  padding-top: 0;
  border-top: 0;
  border-bottom: 0; }

.th-row-subheading:nth-child(2) th,
.th-row-subheading ~ tr td {
  border-top: 0; }

.table tr:last-child td {
  border-bottom: 1px solid #dee2e6; }

.table tr:last-child th > span {
  display: block; }

.table-responsive.mt-30 .table tr:nth-child(2),
.table-responsive.mt-30 .table tr:nth-child(3) {
  font-size: 12px !important; }

.table tr:first-child td,
.table tr:first-child th,
.table tr:not(.title-th-bg):nth-child(2) td {
  border-top: 0; }

.title-bg {
  background-color: #f4fafd;
  min-height: 52px;
  padding: 15px; }

.title-bg .input-md .label {
  margin-top: 2px; }

.title-bg .input-md input[type="checkbox"] + .label:before {
  background: white;
  border: 2px solid #bbbbbb; }

.table-card .title-bg .table-heading {
  margin: 0; }

.title-th-bg {
  font-size: 0.875rem;
  background-color: #fff0c1;
  min-height: 52px;
  padding: 15px; }

.border-top-white th {
  border-top: 4px solid #fff; }

/*
.table-responsive::-webkit-scrollbar {
    height: 10px;
    width: 1em; 
    background-color: slategrey;
    outline: 2px solid slategrey;
    border-radius: 10px;
}
.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*/
select:focus,
select option:focus,
select option:hover {
  outline: none; }

.select-dd {
  min-width: 52px;
  height: 34px;
  padding: 0 0 0 3px;
  margin-left: 5px;
  font-size: 0.8rem;
  border: 1px solid #dadada;
  outline: none; }

td .select-dd {
  border: 1px solid #dadada;
  height: 30px;
  font-size: 0.8rem; }

.table-heading-1 {
  font-size: 24px;
  margin-bottom: 0; }

table input {
  height: 30px; }

.table-title {
  margin-top: 15px;
  margin-bottom: 10px; }

.container .table-title:first-child {
  margin-bottom: 15px; }

.table-title:not(.table-card) .table-heading {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 34px;
  position: relative; }

.donut-min-height {
  min-height: 385px; }

.table-card .table-heading {
  font-size: 18px;
  margin: 20px 0 20px;
  padding-left: 15px; }

.table-card .row .table-heading {
  padding-left: 25px; }

th {
  font-weight: normal;
  /*color: #828282;*/ }

td label:empty {
  display: none; }

.vertical-middle > label {
  margin-bottom: 0; }

/* form - input field */
input[type="number"] {
  height: 30px; }

.form-control:disabled,
.form-control[readonly] {
  color: #000000; }

.field-with-arrow {
  position: relative; }
  .field-with-arrow .btn-qty {
    position: absolute;
    right: 1px;
    top: 1px;
    border-left: 1px solid #eeeeee; }
    .field-with-arrow .btn-qty a {
      height: 14px;
      line-height: 14px;
      display: block;
      width: 20px;
      text-align: center; }
      .field-with-arrow .btn-qty a:first-child {
        border-bottom: 1px solid #e1e1e1; }
  .field-with-arrow input::-webkit-outer-spin-button,
  .field-with-arrow input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .field-with-arrow input[type="number"] {
    -moz-appearance: textfield; }

input[type="number"]:hover::-webkit-inner-spin-button {
  width: 14px;
  height: 30px; }

input:-internal-autofill-selected {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.form-label-inline,
.form-inline-group .form-label,
.form-group .form-label {
  display: block;
  margin-bottom: 5px;
  color: #999999;
  font-size: 14px;
  text-align: left; }

.inline-block,
.form-label-inline,
.form-inline-group .form-label {
  display: inline-block; }

.form-inline-group .form-label {
  color: #161616; }

.form-inline-group {
  padding-left: 0 !important; }

.form-control .react-datepicker__input-container input,
.form-control-edit .form-control,
form .form-control {
  outline: none;
  background-color: white;
  border: 1px solid #dadada;
  width: 100%;
  height: 34px;
  padding: 6px 8px 4px;
  font-size: 14px;
  border-radius: 4px; }

.form-control-edit .form-control {
  border-radius: 0; }

.form-control-edit textarea.form-control {
  height: 80px;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-bottom: 10px; }

.form-control-edit .form-control:focus {
  border: 1px solid #0077ca;
  box-shadow: none; }

.list-module-wrapper {
  max-width: 375px;
  padding-right: 0; }
  .list-module-wrapper .or-divide {
    padding: 0 0 0 20px; }

.history-row .blend-compatibility-wrapper {
  box-shadow: none;
  background-color: #f4fafd;
  padding: 20px;
  margin: 0;
  cursor: pointer; }
  .history-row .blend-compatibility-wrapper:hover {
    box-shadow: 0 0.125rem 0.625rem rgba(0, 115, 208, 0.1), 0 0.0625rem 0.125rem rgba(0, 115, 208, 0.3); }
    .history-row .blend-compatibility-wrapper:hover h3 {
      color: #0077ca; }

.history-date-range {
  max-width: 192px; }
  .history-date-range .date-icon {
    right: 8px;
    position: absolute;
    top: 6px; }
  .history-date-range .form-control .react-datepicker__input-container input,
  .history-date-range form .form-control {
    border-radius: 0; }

.table td,
.table th {
  vertical-align: middle; }

.table th {
  padding: 0.55rem 0.75rem; }

.table-hover-border table {
  padding-right: 1px;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box; }

.table tr.cursor-pointer:nth-child(3) td {
  border-top-color: transparent; }

tr.cursor-pointer:hover td {
  border-top: 1px solid #0077ca;
  border-bottom: 1px solid #0077ca; }

tr.cursor-pointer td:first-child {
  border-left: 1px solid transparent; }

tr.cursor-pointer td:last-child {
  border-right: 1px solid transparent; }

tr.cursor-pointer:hover {
  box-shadow: 0 8px 19px -5px rgba(0, 115, 208, 0.2); }

tr.cursor-pointer:hover td:first-child,
tr.cursor-pointer:hover td:last-child {
  border-color: #0077ca; }

.table tr.cursor-pointer:nth-child(2):hover td,
.table tr.cursor-pointer:nth-child(3):hover td {
  border-top-color: #0077ca; }

tr.cursor-pointer:hover td:first-child {
  border-radius: 4px 0 0 4px; }

tr.cursor-pointer:hover td:last-child {
  border-radius: 0 4px 4px 0; }

.table .form-control,
.table input,
.table .select-dd,
.table select {
  border-radius: 0;
  height: 30px; }

.form-control .react-datepicker__input-container input {
  background-color: transparent; }

.form-control.padding-0 {
  border: 0;
  padding: 0 !important;
  position: relative; }

.react-datepicker-wrapper {
  position: relative;
  z-index: 1; }

.form-group .date-icon {
  position: absolute;
  top: 5px;
  right: 6px;
  font-size: 18px;
  color: #999;
  z-index: 0; }

.form-control .react-datepicker__input-container input:focus,
form .form-control:focus {
  outline: none;
  border: 1px solid #0077ca;
  background-color: white;
  box-shadow: none; }

.form-control .react-datepicker__input-container input:focus {
  background-color: transparent; }

/* form - Radio field */
.radio-wrap .radio.inline {
  margin-right: 10px; }

.radio-wrap .inline {
  display: inline-block; }

.radio-wrap .radio {
  color: #999;
  font-size: 15px;
  position: relative;
  margin-bottom: 0; }

.radio-wrap .radio span:after {
  content: "";
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  box-sizing: border-box; }

.radio-wrap .radio input[type="radio"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.radio-wrap .radio input[type="radio"]:checked + span {
  color: #212529; }

.radio-wrap .radio input[type="radio"]:checked + span:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background: #0077ca;
  left: 4px;
  top: 4px;
  border-radius: 100%; }

.radio-wrap .radio {
  font-size: 0.875rem; }

.radio-wrap .radio input[type="radio"]:checked + span:before {
  background: #0077ca; }

.radio-wrap .radio input[type="radio"]:checked + span:after {
  border-color: #0077ca; }

.radio-wrap .radio span {
  position: relative;
  font-size: 0.875rem;
  padding-left: 24px;
  color: #000000; }

/* custom dropdown */
.dropdown-menu .dropdown-item:focus {
  outline: none; }

.header-actions-menu .dropdown-menu .dropdown-item.active,
.header-actions-menu .dropdown-menu .dropdown-item:active {
  background-color: #0077ca; }

.multiselect-dd .dropdown-item.active,
.multiselect-dd .dropdown-item:active {
  background-color: transparent; }

.multiselect-dd.show > .btn-primary.dropdown-toggle {
  border: solid 1px #dadada;
  background-color: #fff;
  color: #000000; }

.multiselect-dd {
  display: inline-block; }
  .multiselect-dd .btn-primary:hover {
    box-shadow: none;
    border: 0;
    outline: none; }
  .multiselect-dd .btn-primary,
  .multiselect-dd .btn-primary:not(:disabled):not(.disabled).active,
  .multiselect-dd .btn-primary:not(:disabled):not(.disabled):active,
  .multiselect-dd .btn-primary.dropdown-toggle {
    width: 304px;
    height: 34px;
    border: solid 1px #dadada;
    background-color: #fff;
    color: #000000;
    text-align: left;
    border-radius: 0; }
    .multiselect-dd .btn-primary .fa-chevron-down,
    .multiselect-dd .btn-primary:not(:disabled):not(.disabled).active .fa-chevron-down,
    .multiselect-dd .btn-primary:not(:disabled):not(.disabled):active .fa-chevron-down,
    .multiselect-dd .btn-primary.dropdown-toggle .fa-chevron-down {
      padding-top: 4px;
      color: #cccccc; }
  .multiselect-dd .dropdown-menu {
    font-size: 0.8rem;
    color: #000000;
    box-shadow: -5px 7px 13px 0 rgba(137, 165, 202, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    border-color: #fff;
    padding: 0; }
    .multiselect-dd .dropdown-menu .dropdown-item {
      color: #000000;
      height: 40px;
      padding: 8px 1rem;
      border-bottom: 1px solid #e8e7e7; }
      .multiselect-dd .dropdown-menu .dropdown-item:last-child {
        border-bottom: 0; }
      .multiselect-dd .dropdown-menu .dropdown-item:hover {
        color: #0077ca; }
      .multiselect-dd .dropdown-menu .dropdown-item .input-md {
        padding-top: 3px; }
        .multiselect-dd .dropdown-menu .dropdown-item .input-md input[type="checkbox"] {
          width: 100%;
          height: 32px; }

.input-md {
  position: relative; }
  .input-md input[type="checkbox"] {
    width: 20px;
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    height: 20px;
    display: inline-block; }

.input-md input[type="checkbox"] + .label {
  user-select: none;
  padding-left: 25px;
  margin-bottom: 0; }

.input-md input[type="checkbox"] + .label:before {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  content: "";
  border: 2px solid #e6e8ed;
  height: 17px;
  width: 17px;
  border-radius: 2px;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.input-md input[type="checkbox"] + .label:after {
  position: absolute;
  display: block;
  content: "";
  top: 5px;
  left: 5px;
  width: 6px;
  height: 11px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.input-md input[type="checkbox"]:checked + .label:before {
  background-color: #0077ca;
  border-color: rgba(150, 150, 150, 0.1); }

.input-md input[type="checkbox"]:checked + .label:after {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  opacity: 1; }

.input-md input[type="checkbox"][disabled] + .label {
  color: #ccc; }

.input-md input[type="checkbox"][disabled] + .label:before {
  border-color: #ccc; }

.input-md input[type="checkbox"][disabled]:checked + .label:before {
  background: #ccc; }

.table-card {
  background-color: white;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 168, 0.1);
  border-radius: 4px;
  padding: 10px;
  border: 0; }

.merge-card-top {
  padding-bottom: 0; }

/* modal css */
.modal-body {
  padding: 1.7rem 1.8rem 1.9rem; }

.modal-header {
  padding: 1rem 1.8rem; }

.modal-header ~ .modal-body {
  padding-top: 1rem; }

.subscription-plan .form-control input {
  border: 0;
  width: 100%; }

.subscription-hitrate tr:not(:first-child) .text-xsm {
  font-size: 0.875rem !important; }

.modal-header .modal-title {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  font-size: 20px; }

.modal-content .modal-header {
  border: 0; }

.add-crudeoil-table.table thead th {
  font-size: 12px; }

.add-crudeoil-table.table tbody .input-container {
  border-bottom: 1px solid #dadada; }

.add-crudeoil-table.table tbody td {
  padding: 0; }

.add-crudeoil-table.table tbody th,
.add-crudeoil-table.table tbody td {
  width: 10%; }

.add-crudeoil-table.table tbody td .input-element {
  outline: none;
  background-color: #fff;
  height: 24px;
  padding: 6px 8px 4px;
  display: inline-flex;
  width: 85px;
  box-sizing: border-box;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  line-height: normal;
  border-radius: 0; }

.add-crudeoil-table.table tbody .input-container td:first-child .input-element,
.add-crudeoil-table.table tbody .input-container td:last-child .input-element {
  width: 60px; }

.no-item-message {
  color: #656566;
  display: flex;
  font-size: 13px;
  border: 1px solid #dadada;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%; }

.data-table-container {
  max-height: 308px;
  overflow-y: auto;
  box-shadow: 1px 4px 9px -4px rgba(0, 0, 0, 0.3); }

.data-grid-container {
  margin-top: 10px; }

.data-grid-container .custom-data-grid {
  border: 1px solid #dadada;
  padding: 0;
  margin: 0;
  max-height: 256px;
  overflow-y: auto;
  width: 100%; }

.data-grid-container .custom-data-grid ~ .custom-data-grid {
  border-top: 0 none; }

.data-grid-container .custom-data-grid li {
  border-bottom: 1px solid #dadada;
  display: flex;
  width: 100%; }

.data-grid-container .custom-data-grid li:last-child {
  border-bottom: 0 none; }

.data-grid-container .custom-data-grid li.header {
  background-color: #fff0c1; }

.data-grid-container .custom-data-grid li.header .grid-column {
  height: 36px; }

.data-grid-container .custom-data-grid li .grid-column {
  border-right: 1px solid #dadada;
  display: flex;
  font-size: 12px;
  line-height: normal;
  height: 30px;
  padding: 4px 7px;
  flex-wrap: wrap;
  width: 12%; }

.data-grid-container .custom-data-grid li .grid-column span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.data-grid-container .custom-data-grid li .grid-column:last-child {
  border-right: 0 none;
  justify-content: center; }

.delete-crudeoil-modal .modal-lg {
  max-width: 400px; }

.delete-crudeoil-modal .modal-body {
  padding: 5px 15px 20px;
  text-align: center; }

.edit-crudeoil-modal .modal-body {
  padding: 0 15px 20px; }

.add-crudeoil-modal .modal-header .modal-title {
  text-align: left; }

.edit-crudeoil-modal .modal-header .modal-title {
  text-align: left; }

.edit-table tr td {
  padding: 6px; }

.edit-table .title-bg > .col-md-5 {
  padding-left: 0; }

.input-text {
  border: 1px solid #dadada;
  font-size: 14px;
  padding: 6px 8px 4px;
  max-width: 84px; }

.add-crudeoils-container {
  padding: 20px 15px; }

.add-crudeoils-container .card-wraper {
  padding: 0 !important; }

.add-crudeoils-container .card-wraper .table-card {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none; }

.add-crudeoils-container .card-wraper .table-card > .col-sm-12 {
  padding: 0; }

.add-crudeoils-container .btn-container {
  position: relative;
  width: 100%;
  margin-bottom: -35px;
  z-index: 1; }

.researcher-master-crude .btn-container {
  padding: 15px 20px 10px;
  width: 100%; }

.add-blend-modal .modal-body {
  padding: 0 15px 20px; }

.add-blend-modal .modal-header .modal-title {
  text-align: left; }

.add-blend-modal .table-title {
  border: 1px solid #dadada;
  margin: 0; }

.table-card .module-crude .table tr td:last-child {
  width: 75px; }

.add-blend-table .table-responsive {
  max-height: 400px;
  overflow-y: auto; }

.add-blend-table tr td {
  padding: 6px; }

.add-blend-table .title-bg > .col-md-5 {
  padding-left: 0; }

.org-list-table tr:first-child th:nth-last-child(2) {
  text-align: center; }

.org-list-table tr:first-child th:last-child,
.org-list-table tr td:last-child {
  padding: 9px 10px 9px 0;
  width: 52px; }

.user-list-table tr:first-child th:last-child {
  text-align: center; }

.master-crude-grid {
  border: 1px solid #dadada; }

.master-crude-grid-head {
  background-color: #fff0c1; }

.master-crude-grid-head,
.master-crude-grid-body {
  table-layout: fixed; }

.master-crude-grid-head tr th,
.master-crude-grid-body tr td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
  vertical-align: top;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding: 3px 4px;
  border-bottom: 1px solid #dadada;
  width: 12%; }

.master-crude-grid-body tr td .btn-icon {
  min-height: 20px; }

.master-crude-grid-head th .highlight {
  color: red; }

.master-crude-grid-head th > span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  line-height: 15px; }

.master-crude-grid-head tr th:last-child,
.master-crude-grid-body tr td:last-child {
  border-right: 0;
  width: 80px; }

.master-crude-grid-body tr:last-child td {
  border-bottom: 0 none; }

.crude-body-wrapper {
  max-height: 272px;
  overflow-y: auto; }

.master-crude-grid-body tr td {
  vertical-align: middle; }

.master-crude-grid-body tr td input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  height: 16px; }

.add-crudeoil-modal .modal-body {
  padding: 5px 15px 5px; }

.add-crudeoil-modal .modal-footer {
  border-top: 0 none; }

.grid-wrapper {
  border: 2px solid #dadada; }

.grid-head {
  background-color: #fff0c1; }

.grid-head,
.grid-body {
  table-layout: fixed; }

.grid-head tr th,
.grid-body tr td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
  vertical-align: top;
  font-size: 13px;
  text-align: center;
  padding: 3px 4px;
  border-bottom: 1px solid #dadada;
  width: 14%; }

.grid-head th .highlight {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold; }

.grid-head th > span {
  font-size: 12px;
  line-height: 15px; }

.grid-head tr th:first-child,
.grid-body tr td:first-child {
  width: 40px; }

.grid-head tr th:nth-child(2),
.grid-body tr td:nth-child(2) {
  width: 30px; }

.grid-head tr th:nth-child(2) input[type="checkbox"] {
  height: 20px; }

.grid-head tr th:nth-child(n+3):nth-child(-n+4),
.grid-body tr td:nth-child(n+3):nth-child(-n+4),
.grid-head tr th:nth-child(n+3):nth-child(-n+4),
.grid-body tr td:nth-child(n+3):nth-child(-n+4) {
  width: 14%; }

.grid-head tr th:last-child,
.grid-body tr td:last-child {
  border-right: 0; }

.grid-head tr th:nth-last-child(-n+5),
.grid-body tr td:nth-last-child(-n+5) {
  width: 50px; }

.grid-body-wrapper {
  max-height: 410px;
  overflow-y: auto; }

.grid-body tr td {
  vertical-align: middle; }

.grid-body tr td input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  height: 16px; }

.highlighter {
  font-size: 13px;
  color: red;
  margin: 10px 0 0; }

.count {
  display: inline;
  vertical-align: middle;
  font-size: 18px; }

.info-text {
  color: #969696;
  font-size: 13px;
  margin: 5px 0 0; }

.info-message {
  font-size: 12px;
  color: red; }

.info-message2 {
  font-size: 12px;
  color: #969696; }

.coeffecient-input-wrapper {
  width: 100%; }

.coeffecient-input-container {
  padding: 0;
  min-height: 240px;
  max-height: 560px;
  padding-bottom: 20px;
  overflow-x: auto;
  /*white-space: nowrap;*/
  width: 100%; }

table.coefficients {
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  margin: auto; }

.coefficients td.sticky-column {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  border-right: 1px solid #dee2e6;
  left: 0;
  background-color: white;
  z-index: 2;
  min-width: 261px;
  width: 261px;
  box-shadow: 0px 4px 3px 2px rgba(222, 222, 222, 0.8); }

.coefficients.constraints td.sticky-column,
table.coefficients.constraints th:first-child {
  min-width: 272px;
  width: 272px; }

.formula-container {
  max-width: 46%; }

.constraints-input-edit {
  max-width: 52%; }

.row-coeffecients {
  display: flex;
  flex-direction: row; }

.row-coeffecients.label-text .col-input {
  color: grey;
  padding-left: 6px;
  font-size: 12px;
  text-align: left; }

.row-coeffecients .col-input {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 120px; }

input.coefficient-values {
  border-radius: 0;
  border: 1px solid #dadada;
  height: 30px;
  margin: 2px 5px 6px;
  width: 100px; }

table.coefficients th {
  background-color: #fff0c1; }

table.coefficients th:first-child {
  border-right: 1px solid #dee2e6;
  width: 261px; }

table.coefficients td:first-child {
  width: 261px; }

table.coefficients th, table.coefficients td {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 4px; }

table.coefficients tr:last-child td {
  border-bottom: 0 none; }

.add-4A-input-modal .modal-title.h4 {
  margin-left: -12px; }

table.module4A {
  width: 100%;
  margin: auto; }

table.module4A th, table.module4A td {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 4px; }

table.module4A th, table.module4A td {
  width: 50%; }

table.module4A th:nth-child(2), table.module4A td:nth-child(2) {
  width: 250px; }

table.module4A th:last-child, table.module4A td:last-child {
  width: calc(50% - 250px); }

table.module4A tr:last-child td {
  border-bottom: 0 none; }

table.coefficients th,
table.module4A th {
  background-color: #fff0c1; }

input.module4A-values {
  border-radius: 0;
  border: 1px solid #dadada;
  height: 30px;
  margin: 6px 5px;
  width: 72px; }

input.module4A-values {
  width: 240px; }

.btn-wrapper {
  border-top: 1px solid #dee2e6;
  padding: 20px 10px; }

.btn-wrapper .btn-primary {
  margin-right: 10px; }

.radio > input[type="radio"]:disabled {
  cursor: default; }

.radio > input[type="radio"]:disabled ~ span {
  opacity: 0.5; }

.new-blend-list {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6; }

.new-blend-list .title-th-bg {
  min-height: 22px;
  padding: 5px 15px; }

.new-blend-list .title-th-bg td {
  padding: 0; }

.new-blend-list .title-th-bg td span {
  display: block;
  padding: 5px 12px;
  width: 100%; }

.new-blend-list td input {
  outline: none;
  background-color: white;
  border: 1px solid #dadada;
  width: 100%;
  height: 30px;
  padding: 6px 8px 4px;
  display: block;
  width: calc(100% + 1px);
  box-sizing: border-box;
  font-size: 13px;
  border-radius: 0; }

.results-wrapper {
  background-color: #fff;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 168, 0.1);
  border-radius: 4px; }

.output-header-container {
  position: relative;
  z-index: 1; }

.with-blend-container .dy-table {
  position: relative; }

.with-blend-container .dy-table:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 64px;
  right: 0;
  background-color: #f4fafd;
  top: -74px;
  z-index: 0; }

.results-wrapper h4 ~ .output-header-container .table-card {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  margin-bottom: 10px; }

.results-wrapper h4 ~ .output-header-container .table-card .row.table-title {
  display: none; }

.blinking-text {
  color: red;
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite; }

@keyframes blink-animation {
  to {
    visibility: hidden; } }

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden; } }

.module4-results {
  padding: 20px; }

.row-wrapper {
  display: flex;
  justify-content: space-between; }

.module4-results h4 {
  font-size: 16px; }

.module4-results table {
  border: 0 none; }

.module4-results table th {
  background-color: #fff0c1;
  border: 0 none;
  font-size: 13px; }

.module4-results table td {
  border-color: #d2caca;
  text-align: center;
  font-size: 13px; }

.border-bottom {
  border-bottom: 1px solid #dadada; }

.results-container {
  width: 100%;
  overflow-y: auto; }

.module4-results .with-blend-container table th {
  text-align: center;
  min-width: 78px; }

.with-blend-container thead tr {
  border-bottom: 3px solid #fff; }

.with-blend-container thead tr:first-child th {
  border-right: 3px solid #fff; }

.with-blend-container tbody tr {
  border-top: 1px solid #d2caca; }

.module4-results table td {
  border-left: 0 none;
  padding: 12px 0 12px 0; }

.module4-results table td {
  border-right: 0 none; }

.module4-results table tr.cursor-pointer:hover td:first-child {
  border-radius: 4px 0 0 4px; }

.module4-results table tr.cursor-pointer:hover td:last-child {
  border-radius: 0 4px 4px 0; }

.with-blend-container thead tr:last-child {
  border-bottom: 0 none; }

.with-blend-container thead tr:last-child {
  border-right: 2px solid #fff; }

.nlp-text {
  color: red;
  font-weight: bold; }

.lp-text {
  color: green;
  font-weight: bold; }

.ilp-text {
  color: orange;
  font-weight: bold; }

.lobs-status {
  display: inline-block;
  padding: 0 15px 0 10px; }

.meter-container {
  position: relative; }

.meter-container .potential-value {
  position: absolute;
  top: 12px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto; }

.meter {
  height: 15px;
  border-radius: 50px;
  position: relative;
  background: #f3efe6;
  overflow: hidden; }

.meter span {
  display: block;
  height: 100%; }

.progress {
  background-color: #e4c465;
  -webkit-animation: progressBar 3s ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar 3s ease-in-out;
  -moz-animation-fill-mode: both; }

@-webkit-keyframes progressBar {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@-moz-keyframes progressBar {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.nlp-progress .progress {
  background-color: red; }

.lp-progress .progress {
  background-color: green; }

.ilp-progress .progress {
  background-color: #e4c465; }

.btn-text {
  color: #0077ca;
  background-color: transparent;
  border: 0 none;
  text-decoration: underline; }

.add-4A-input-modal .modal-body {
  padding: 0 15px 20px; }

.add-4A-input-modal .modal-header .modal-title {
  text-align: left; }

.add-4A-input-modal .table-title {
  border: 1px solid #dadada;
  margin: 0; }

.inputs-container {
  max-height: 400px;
  padding-right: 5px;
  overflow-y: auto; }

.module-criteria-container {
  margin-bottom: 20px;
  border-right: 1px solid #dadada;
  border-bottom: 2px solid #dadada;
  height: 869px;
  overflow-y: auto;
  padding: 5px 10px; }

.criteria-input-container {
  height: 100%;
  max-height: 100%;
  padding-bottom: 0; }

.constraints-input-edit .btn-wrapper {
  margin-top: 0 !important; }

.module-row {
  border-bottom: 1px solid #dadada;
  padding: 5px 0 15px; }

.module-row.no-border {
  border: 0 none; }

.module-criteria-container h2 {
  color: #272727;
  margin-bottom: 0;
  font-size: 16px; }

.module-criteria-container h3 {
  color: #333;
  font-size: 15px; }

.coeffecient-input-container .coefficient-values,
.module-criteria-container .criteria-values {
  -moz-appearance: textfield;
  /* -webkit-appearance: none;
    margin: 0; */ }

.module-criteria-container .criteria-values {
  border: 1px solid rgba(218, 218, 218, 0.3);
  height: 20px;
  margin: 5px 0;
  padding: 3px 5px;
  text-align: center;
  width: 62px; }

.formula-table {
  border: 1px solid;
  width: 100%; }

.module1-table {
  background-color: rgba(247, 113, 113, 0.3); }

.module2-table {
  background-color: rgba(120, 228, 235, 0.4); }

.module3-table {
  background-color: rgba(255, 222, 76, 0.4); }

.module4-table {
  background-color: rgba(102, 255, 75, 0.4); }

.criteria-input-container .coefficients tr:first-child {
  display: none; }

.criteria-input-container .coefficients tr td.module-space {
  background-color: transparent;
  padding: 0;
  height: 15px; }

.criteria-input-container .coefficients tr:nth-child(1n+1):nth-child(-n+6) {
  background-color: rgba(247, 113, 113, 0.3);
  /* Applies to rows 1 to 5 */ }

.criteria-input-container .coefficients tr:nth-child(8) {
  background-color: rgba(120, 228, 235, 0.4);
  /* Applies to row 6 */ }

.criteria-input-container .coefficients tr:nth-child(n+10):nth-child(-n+12) {
  background-color: rgba(255, 222, 76, 0.4);
  /* Applies to rows 7 to 9 */ }

.criteria-input-container .coefficients tr:nth-child(n+14):nth-child(-n+16) {
  background-color: rgba(102, 255, 75, 0.4);
  /* Applies to rows 10 to 12 */ }

.criteria-input-container .coefficients td.sticky-column {
  background-color: transparent;
  box-shadow: 0px 4px 3px 2px rgba(222, 222, 222, 0.2); }

.coefficients .title-th-bg.no-bg,
.coefficients .title-th-bg.no-bg th {
  background-color: transparent !important; }

.color-white th.pl-0 {
  padding-bottom: 7px; }

.color-white {
  background-color: #fff; }

.formula-table td {
  border-color: rgba(0, 0, 0, 0.1); }

.formula-table,
.formula-table tr
.formula-table thead,
.formula-table tbody,
.formula-table th {
  border: 0 none; }

.formula-table tr.no-border th,
.formula-table tr.no-border,
.formula-table tr.no-border td,
.formula-table tr.no-border {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important; }

.formula-table th {
  color: #272727; }

.formula-table tbody tr td {
  text-align: center; }

.formula-table thead tr th,
.formula-table tbody tr td {
  padding: 3px 5px; }

.formula-table tbody tr td:first-child {
  width: 36px; }

.formula-table tbody tr td:nth-child(2) {
  width: 120px; }

.formula-table tbody tr td.set-col-96x {
  width: 96px; }

.formula-table tbody tr td.set-col-106x {
  width: 106px; }

.formula-table tbody tr td.set-col-138x {
  width: 138px; }

.formula-table tbody tr td:last-child {
  text-align: left;
  width: 103px; }

.formula-table tbody tr td[colspan="2"].text-right {
  padding-right: 30px; }

.formula-table tbody tr td[colspan="2"].text-right.input {
  padding-right: 10px; }

.formula-table tbody tr.no-border,
.formula-table tbody tr.no-border td {
  height: 18px; }

.formula-table.no-top-border {
  border-top: 1px solid transparent; }

.formula-table.no-bottom-border {
  border-bottom: 1px solid transparent; }

.formula {
  color: #0077ca; }

.txt-compatible {
  color: green; }

.txt-intermediate {
  color: orange; }

.txt-incompatible {
  color: red; }

.txt-descript {
  color: #777777; }

.compatible-criteria ul {
  font-size: 13px;
  list-style: decimal;
  list-style-position: inside;
  padding: 0;
  margin: 0; }

.compatible-criteria ul li {
  padding: 5px 10px; }

.mastercrude-input-table tr th:first-child {
  padding: 8px 0 0 5px;
  width: 45px; }

.mastercrude-input-table tr td:first-child {
  padding: 8px 0 0;
  font-size: 13px;
  width: 45px; }

/* Button common css */
.btn-icon,
.btn-secondary,
.btn-primary {
  background-color: #0077ca;
  min-width: 90px;
  min-height: 34px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  padding: 5px 15px; }

.btn-transition {
  transition: all 0.7s ease; }

.btn-secondary {
  background-color: transparent;
  color: #0077ca;
  border: solid 1px #0077ca; }

.btn-primary > a:first-child {
  color: #fff !important; }

.btn-primary > a:first-child:hover {
  color: #fff !important;
  text-decoration: none !important; }

.btn-secondary .fa-file-excel {
  font-size: 1rem;
  margin-right: 2px; }

.btn-icon {
  background-color: transparent;
  min-width: 34px;
  min-height: 30px;
  color: #3b3b3b;
  border: 0;
  padding: 0; }

.dropdown-toggle.btn-icon {
  padding: 0.375rem 0.75rem; }

.add-user {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 10px !important;
  display: inline-block;
  vertical-align: bottom; }

.btn-primary .fa-user {
  margin-right: 5px;
  font-size: 1rem;
  color: #f9f9f9; }

.btn-icon .fa-ellipsis-v {
  font-size: 1rem; }

.btn-icon .fa-pencil-alt {
  font-size: 0.85rem;
  color: #a9a9a9; }
  .btn-icon .fa-pencil-alt:hover {
    color: #0077ca; }

.btn-icon:active, .btn-icon:focus {
  outline: none; }

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important; }

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .secondary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0077ca;
  border-color: #0077ca;
  color: white;
  box-shadow: none; }

.btn-secondary:hover,
.btn-primary:hover {
  /*box-shadow: 0 0.125rem 0.625rem rgba(52,58,64,0.4), 0 0.0625rem 0.125rem rgba(52,58,64,0.5);
  box-shadow: 0 0.125rem 0.625rem rgba(0, 115, 208, 0.3), 0 0.0625rem 0.125rem rgba(0, 115, 208, 0.5);*/
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }

.btn-primary:hover {
  background-color: #0260a2; }

.btn-secondary:hover {
  background-color: white;
  border-color: #0077ca;
  color: #0077ca; }

.btn-secondary:active {
  background-color: transparent;
  border-color: #0077ca;
  color: #0077ca; }

.btn-secondary.focus,
.btn-secondary:focus,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #0260a2;
  border-color: #0260a2;
  color: #fff; }

.btn {
  font-size: 0.875rem !important; }

.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn-secondary:disabled,
.btn-secondary.disabled,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  color: #fff;
  background-color: #0077ca;
  cursor: not-allowed;
  opacity: 0.5;
  box-shadow: none;
  border: 0; }

.btn-primary:focus,
.btn-secondary:focus {
  box-shadow: none !important;
  outline: none !important; }

.btn-icon:hover,
.btn-icon:focus {
  background-color: transparent;
  color: #0077ca; }

.btn-icon:disabled,
.btn-icon.disabled,
.btn-icon.disabled:hover,
.btn-icon:disabled:hover {
  color: #999999;
  background-color: transparent;
  cursor: not-allowed; }

.edit-heading {
  display: inline-block; }

/* drop down css */
.dropdown-toggle:after,
.link-dropdown .dropdown-toggle:after,
.researcher-actions-menu .dropdown-toggle:after {
  display: none; }

.dropdown-menu .dropdown-item {
  border-bottom: 1px solid #e8e7e7;
  padding: 0.35rem 1.5rem; }
  .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0; }
  .dropdown-menu .dropdown-item:last-child:hover {
    border-bottom: 0; }

.link-dropdown .dropdown-menu {
  width: 135px;
  height: 90px;
  border-radius: 2px;
  box-shadow: -7px 9px 15px 0 rgba(137, 165, 202, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #d1d1d1;
  padding-top: 0;
  height: auto;
  padding-bottom: 0; }

.link-dropdown .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #e8e7e7;
  padding: 0.35rem 1.5rem;
  font-size: 0.8rem; }
  .link-dropdown .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0; }
  .link-dropdown .dropdown-menu .dropdown-item:hover {
    color: #0077ca; }

.link-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.link-dropdown .show > .btn-primary.dropdown-toggle:focus,
.link-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.link-dropdown .btn-primary.btn-icon:hover,
.link-dropdown .btn-primary.btn-icon:focus,
.link-dropdown .btn-primary.btn-icon:active,
.link-dropdown.show > .btn-primary.dropdown-toggle,
.link-dropdown.show > .btn-primary.dropdown-toggle:focus,
.researcher-actions-menu
.btn-primary:not(:disabled):not(.disabled):active:focus,
.researcher-actions-menu .show > .btn-primary.dropdown-toggle:focus,
.researcher-actions-menu .btn-primary:not(:disabled):not(.disabled):active,
.researcher-actions-menu .btn-primary.btn-icon:hover,
.researcher-actions-menu .btn-primary.btn-icon:focus,
.researcher-actions-menu .btn-primary.btn-icon:active,
.researcher-actions-menu.show > .btn-primary.dropdown-toggle,
.researcher-actions-menu.show > .btn-primary.dropdown-toggle:focus {
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: solid 1px #3b3b3b;
  color: #3b3b3b;
  background-color: transparent;
  outline: none;
  box-shadow: none; }

.researcher-actions-menu .dropdown-menu.show {
  left: -120px !important; }

.link-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.link-dropdown .show > .btn-primary.dropdown-toggle:focus,
.link-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.link-dropdown .btn-primary.btn-icon:hover,
.link-dropdown .btn-primary.btn-icon:focus,
.link-dropdown .btn-primary.btn-icon:active,
.link-dropdown.show > .btn-primary.dropdown-toggle,
.link-dropdown.show > .btn-primary.dropdown-toggle:focus {
  width: auto;
  height: auto;
  border: 0;
  color: #3b3b3b;
  background-color: transparent; }

.link-dropdown .inline-block {
  vertical-align: top; }

.link-dropdown .user-name,
.link-dropdown .user-role {
  display: block;
  color: #000000;
  margin-left: 10px;
  font-size: 0.8rem;
  text-align: left;
  width: 98px;
  text-overflow: ellipsis;
  overflow: hidden; }

.link-dropdown .user-role {
  color: #656566;
  font-size: 12px; }

/* header dropdown menu */
ul .header-actions-menu {
  margin-top: 12px;
  min-height: 50px; }
  ul .header-actions-menu .btn-icon {
    padding-right: 0.5rem; }
  ul .header-actions-menu .user-icon {
    background-color: #cceeff;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: inline-block; }
  ul .header-actions-menu .fa-user {
    margin-right: 0;
    font-size: 22px;
    color: #0077ca;
    line-height: 32px; }
  ul .header-actions-menu .btn-icon .inline-block:last-child {
    line-height: 17px; }
  ul .header-actions-menu .dropdown-menu.show {
    top: 10px !important;
    border: 0; }
  ul .header-actions-menu .dropdown-menu {
    box-shadow: -7px 9px 15px 0 rgba(137, 165, 202, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    overflow: hidden; }
    ul .header-actions-menu .dropdown-menu .dropdown-item {
      padding: 0.45rem 1rem;
      font-size: 0.8rem;
      border-bottom: 0; }
      ul .header-actions-menu .dropdown-menu .dropdown-item .float-right {
        color: rgba(0, 0, 0, 0.5); }
      ul .header-actions-menu .dropdown-menu .dropdown-item:hover, ul .header-actions-menu .dropdown-menu .dropdown-item:active, ul .header-actions-menu .dropdown-menu .dropdown-item:focus {
        color: #0077ca;
        background-color: #e9ecef; }
        ul .header-actions-menu .dropdown-menu .dropdown-item:hover .float-right, ul .header-actions-menu .dropdown-menu .dropdown-item:active .float-right, ul .header-actions-menu .dropdown-menu .dropdown-item:focus .float-right {
          color: rgba(0, 0, 0, 0.5); }
      ul .header-actions-menu .dropdown-menu .dropdown-item:nth-child(1), ul .header-actions-menu .dropdown-menu .dropdown-item:nth-child(2), ul .header-actions-menu .dropdown-menu .dropdown-item:nth-child(3) {
        padding-top: 0.45rem;
        padding-bottom: 0.45rem; }
      ul .header-actions-menu .dropdown-menu .dropdown-item:last-child {
        border-bottom: 1px solid #ffffff;
        border-top: 1px solid #e8e7e7; }

.vr-dd.status-dd {
  width: auto;
  min-width: 58px;
  display: inline-block; }

.vr-dd.status-dd .dropdown-menu {
  min-width: 56px; }

.header-actions-menu .dropdown-menu .hide-lg {
  display: none; }

@media (max-width: 499px) {
  .link-dropdown .user-name,
  .link-dropdown .user-role {
    width: 74px; }
  nav ul .btn-primary.ml-20 {
    display: none; }
  .header-actions-menu .dropdown-menu .hide-lg {
    display: inline-block; }
  header .container:first-child {
    padding-left: 0;
    padding-right: 0; }
  ul .header-actions-menu .dropdown-menu.show {
    min-width: 145px;
    width: 145px; } }

.cost-dropdown .dropdown-toggle.btn-icon {
  color: black;
  padding-bottom: 0;
  padding-top: 0;
  min-height: 20px; }

.cost-dropdown .dropdown-menu {
  min-width: 84px;
  width: 84px; }
  .cost-dropdown .dropdown-menu .dropdown-item {
    background-color: white;
    padding: 0.35rem 1rem;
    cursor: pointer; }
    .cost-dropdown .dropdown-menu .dropdown-item:active, .cost-dropdown .dropdown-menu .dropdown-item:focus {
      color: black; }
    .cost-dropdown .dropdown-menu .dropdown-item:hover {
      padding: 0.35rem 1rem;
      background-color: #e9ecef;
      color: #0077ca; }
  .cost-dropdown .dropdown-menu .inline-block {
    width: 100%; }

.cost-dropdown .fa-chevron-down {
  color: #999999;
  margin-left: 5px;
  padding-top: 5px;
  font-size: 12px; }

.status-dd {
  min-width: 82px; }
  .status-dd .dropdown-menu {
    min-width: 90px;
    width: 94px;
    border: 0; }
    .status-dd .dropdown-menu .dropdown-item:disabled {
      cursor: not-allowed;
      background-color: #9a9a9a;
      color: white;
      opacity: 0.7; }
    .status-dd .dropdown-menu .inline-block {
      width: 100%; }
  .status-dd .fa-chevron-down {
    color: #999999;
    margin-left: 5px;
    padding-top: 5px;
    font-size: 12px; }

.status-change .dropdown-menu {
  width: 100%; }
  .status-change .dropdown-menu .dropdown-item {
    cursor: pointer; }
    .status-change .dropdown-menu .dropdown-item:active {
      color: #fff; }

.status-change .dropdown-toggle:hover,
.status-change .dropdown-toggle {
  width: 100% !important;
  text-align: left; }

.status-change.show .dropdown-menu {
  top: -2px !important; }

/* tooltip */
.tooltip,
.cell-toolip {
  position: relative; }

.tooltip-error-message {
  display: none; }

.cell-toolip .tooltip-error-message {
  display: inline-block; }

.tooltip-error-icon {
  position: absolute;
  color: #d0021b;
  top: 3px;
  height: 24px;
  width: 20px;
  font-size: 16px;
  right: 1px;
  cursor: pointer;
  background-color: white; }

.tooltip-error-icon ~ .tooltiptext,
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  line-height: normal;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s; }

.tooltip-error-icon ~ .tooltiptext:after,
.tooltip .tooltiptext:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent; }

.tooltip-error-icon ~ .tooltiptext {
  min-height: 14px;
  min-width: 84px;
  width: auto;
  max-width: 204px;
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.3), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.4);
  background-color: #d0021b;
  font-size: 12px;
  border-radius: 2px;
  padding: 1px 3px;
  position: absolute;
  z-index: 1;
  top: 118%;
  left: 0;
  bottom: auto;
  right: 0;
  margin: 0 auto; }

.tooltip-error-icon ~ .tooltiptext:after {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent #d0021b transparent; }

/* tooltip for last row on top */
.overflow-visible
.table
tr:last-child
.tooltip-error-icon
~ .tooltiptext:after {
  top: auto;
  bottom: -10px;
  left: 50%;
  border-color: #d0021b transparent transparent transparent; }

.overflow-visible .table tr:last-child .tooltip-error-icon ~ .tooltiptext {
  top: auto;
  left: 0;
  bottom: 36px;
  right: 0; }

.tooltip-error-icon:hover ~ .tooltiptext,
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

/* vertical tooltip */
.tooltip-vertical-error {
  position: relative;
  display: inline-block;
  margin-right: 2px; }

.vertical-icon {
  color: #d0021b;
  font-size: 18px;
  cursor: pointer; }

.tooltip-vertical-error .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #d0021b;
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.3), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.4);
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  position: absolute;
  z-index: 1; }

.tooltip-vertical-error .tooltip-text {
  top: -21px;
  right: 20px; }

.tooltip-vertical-error .tooltip-text:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #d0021b; }

.tooltip-vertical-error:hover .tooltip-text {
  visibility: visible; }

/* blend compatibility  */
.blend-compatibility-wrapper {
  background-color: #fff;
  padding: 25px;
  margin: 0 15px 15px;
  box-shadow: 0 2px 20px 0 #e5ebef;
  border-radius: 4px; }
  .blend-compatibility-wrapper .text-md {
    color: #626262; }
  .blend-compatibility-wrapper .btn-secondary:hover {
    background-color: #0077ca;
    color: #ffffff;
    box-shadow: none; }

.blend-compatibility-wrapper > div {
  padding: 10px 0 20px; }

.blend-compatibility-wrapper.mlr-0 > div {
  padding: 0; }

a.blend-compatibility-wrapper:hover {
  text-decoration: none; }

a.blend-compatibility-wrapper h3,
a.blend-compatibility-wrapper .text-xsm {
  color: #000000; }

a.blend-compatibility-wrapper:hover h3 {
  color: #0077ca; }

a.blend-compatibility-wrapper:hover .text-md {
  color: #626262;
  text-decoration: none; }

a.blend-compatibility-wrapper:hover .text-md {
  text-decoration: none; }

.search-container {
  border-radius: 17px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
  display: inline-block; }
  .search-container .search-field {
    outline: none;
    background-color: white;
    border: none;
    border-radius: 17px;
    height: 30px;
    padding-left: 6px; }

.user-count {
  font-size: 15px;
  color: #757575; }

/* profile page */
.oval {
  width: 96px;
  height: 96px;
  border: solid 3px #fff;
  background-color: #d8d8d8;
  border-radius: 100%;
  text-align: center;
  position: relative; }
  .oval .fa-user {
    font-size: 64px;
    color: #575757;
    margin-top: 9px; }
  .oval .edit-profile-image {
    height: 20px !important;
    width: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
    border-radius: 100%;
    margin-top: 20px;
    border: solid 1px #979797;
    background-color: white;
    display: block;
    position: absolute;
    top: 32px;
    right: -8px; }
    .oval .edit-profile-image:hover {
      border: solid 1px #0077ca !important; }
      .oval .edit-profile-image:hover .fa-pencil-alt {
        color: #0077ca; }
    .oval .edit-profile-image .fa-pencil-alt {
      text-align: center;
      font-size: 10px;
      color: #979797;
      line-height: 19px;
      vertical-align: top;
      display: inline-block;
      margin: 0 auto;
      padding-left: 1px; }

.profile-img {
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background: transparent;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: solid 1px #979797; }
  .profile-img .fa-user {
    display: none; }

.profile-icon-wrapper {
  max-width: 135px !important; }

.club-with-tabs-wrapper {
  position: absolute;
  right: 0;
  top: 10px; }

.status-change {
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #dadada;
  background-color: #fff; }

#controlled-tab-example-tabpane-2 div[class*="Auth_Auth"] {
  box-shadow: none;
  text-align: left;
  margin: 15px 0 0; }
  #controlled-tab-example-tabpane-2 div[class*="Auth_Auth"] .info {
    display: none; }

#controlled-tab-example-tabpane-2 h2 {
  display: none; }

/* chart */
.apexcharts-canvas {
  margin: 0 auto; }

/* alert message style */
.alert-danger {
  padding: 15px 15px 12px;
  background-color: #f8d7da;
  border-radius: 4px; }
  .alert-danger i {
    font-size: 1rem;
    color: #444444;
    margin-right: 5px; }

.alert-success i {
  margin-right: 5px;
  font-size: 1rem; }

.Alert {
  position: fixed;
  top: 75px;
  width: 360px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
  z-index: 2000;
  border-radius: 4px;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08), -10px 9px 21px 0 rgba(128, 152, 213, 0.08);
  background-color: #ffffff; }
  .Alert .alert-message {
    vertical-align: bottom; }

.badge {
  margin-right: 5px;
  padding: 5px;
  border-radius: 50%;
  background: #d0021b;
  color: white; }

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (min-width: 576px) and (max-width: 991px) {
  .mt-sm-10 {
    margin-top: 10px !important; }
  .mt-sm-20 {
    margin-top: 20px !important; } }

/* password meter */
.btn-link-custom {
  color: #0077ca;
  float: right;
  font-size: 12px;
  padding: 7px 0;
  border: 0;
  margin: 0 7px;
  position: relative;
  background-color: transparent; }

.square-bracket:after,
.square-bracket:before {
  left: -6px;
  content: "[";
  height: 0;
  width: 0;
  position: absolute;
  color: #000000; }

.square-bracket:before {
  left: auto;
  right: -3px;
  content: "]"; }

.btn-link-custom:hover,
.btn-link-custom:focus {
  text-decoration: underline;
  outline: none;
  box-shadow: none;
  color: #0077ca; }

.square-bracket.btn-link-custom:after:focus,
.square-bracket.btn-link-custom:before:focus,
.square-bracket.btn-link-custom:after:hover,
.square-bracket.btn-link-custom:before:hover {
  text-decoration: none !important; }

.arrow-top:after,
.arrow-top:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.arrow-top:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: 9px;
  margin-left: -9px; }

.arrow-top:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #ccc;
  border-width: 10px;
  margin-left: -10px; }

.password-meter-wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #777777;
  position: absolute;
  background-color: #fff;
  height: auto;
  display: block;
  width: 100%;
  z-index: 1;
  top: -144px;
  padding: 10px 15px 0;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.35), 0 0.0025rem 0.025rem rgba(0, 0, 0, 0.35); }
  .password-meter-wrapper .strength {
    display: none; }

.update-pwd .password-meter-wrapper {
  top: -70px; }

.reset-pwd .square-bracket,
.update-pwd .square-bracket {
  position: absolute;
  right: 1px;
  top: 80px; }

.reset-pwd .square-bracket {
  top: -5px; }

.progress-bar-wrap {
  height: 5px;
  margin-bottom: 10px;
  line-height: 5px;
  width: 100%; }
  .progress-bar-wrap .progress-bar {
    height: 5px;
    width: 32.3%;
    margin-right: 0.19rem;
    background-color: #dddeee;
    display: inline-block;
    overflow: hidden;
    border-radius: 5px; }
    .progress-bar-wrap .progress-bar:last-child {
      margin-right: 0; }

.weak-pwd .progress-bar-line-1 {
  background-color: #ff4b47; }

.weak-pwd .weak {
  display: block; }

.good-pwd .progress-bar-line-1,
.good-pwd .progress-bar-line-2 {
  background-color: #f9ae35; }

.good-pwd .good {
  display: block; }

.strong-pwd .progress-bar-line-1,
.strong-pwd .progress-bar-line-2,
.strong-pwd .progress-bar-line-3 {
  background-color: #2daf7d; }

.strong-pwd .strong {
  display: block; }

.password-tips {
  list-style: none;
  padding: 0 0 0 5px;
  font-size: 0.875rem; }
  .password-tips li:before {
    content: "\f111";
    display: inline-block;
    margin-left: 0em;
    width: 2.8em;
    font-family: "Font Awesome 5 Free";
    font-size: 6px;
    text-align: center;
    vertical-align: middle;
    font-weight: 900; }
  .password-tips .password-fulfilled {
    /*text-decoration: line-through;*/
    color: #2daf7d; }
    .password-tips .password-fulfilled:before {
      content: "\f00c";
      font-size: 11px;
      width: 1.55em; }

/* Reminder message */
.reminder-msg {
  margin-top: 20px; }
  .reminder-msg .fa-bell {
    font-size: 1.2rem;
    margin-right: 10px;
    color: #721c24; }
  .reminder-msg .close {
    background-color: transparent;
    margin-top: -14px;
    margin-right: -14px;
    opacity: 0.65;
    padding: 0 3px 2px; }
    .reminder-msg .close:focus {
      outline: none !important; }

/* Help docuemnt */
.help-content {
  padding: 30px;
  max-width: 90%;
  margin: 0 auto; }

.help-content .table-heading {
  padding-left: 0;
  margin-top: 0; }

.image-desc {
  color: #888888;
  margin-top: 10px;
  display: block;
  font-size: 14px; }

.img-border {
  border: 1px solid #ddd;
  max-width: 100%;
  margin-top: 15px; }

@media (max-width: 1024px) and (max-height: 350px) {
  html,
  body {
    height: 100%; }
  body .ui-wrapper {
    min-height: 100%;
    position: relative;
    padding-bottom: 40px; } }

@media (max-height: 570px) {
  html,
  body {
    height: 100%; }
  body .ui-wrapper {
    min-height: 100%;
    position: relative;
    padding-bottom: 40px; } }
